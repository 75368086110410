<template>
  <div>
    <b-row>
      <b-col>
        <dashboard></dashboard>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {

}
</script>

<style>

</style>
